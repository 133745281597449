import { createApp } from "vue";
import App from "./App.vue";
import "./css/index.css";
import router from "./router/index.js";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import '@asika32764/vue-animate/dist/vue-animate.css';
import MathJax, { initMathJax, renderByMathjax } from "mathjax-vue3";
import Popper from "vue3-popper";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import mitt from "mitt";
import VueExcelEditor from "vue3-excel-editor";
import math from "./plugins/mathjs";
import { Buffer } from "buffer";
import currencyBadge from "vue-currency-symbol";
import vueMny from "vue-mny";

globalThis.Buffer = Buffer;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const config = {
  epsilon: 1e-12,
  matrix: "Matrix",
  number: "number",
  precision: 64,
  predictable: false,
  randomSeed: null,
};

function onMathJaxReady() {
  // The parent node of need to be rendered into the formula node set
  const el = document.getElementById("elementId");
  // ❗️❗️ When there is no el will begin to render the default root node
  renderByMathjax(el);
}

initMathJax({}, onMathJaxReady);

const eventBus = new mitt();
const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(Toast);
app.use(MathJax);
app.use(currencyBadge);
app.use(vueMny, {
  locale: "en-GB",
  currency: "GBP",
  currencyDisplay: "symbol",
});
app.use(VueExcelEditor);
app.use(math, config);
app.component("Popper", Popper);
app.config.globalProperties.$eventBus = new mitt();
app.provide("eventBus", eventBus);
app.mount("#app");
