<template>
  <div
    :class="[selectedTheme, 'bg-background flex flex-col flex-1 w-full h-full']"
  >
    <div class="flex flex-col-1 flex-1 w-full h-full items-start">
      <div class="w-full">
        <router-view />
      </div>
    </div>
    <div class="h-screen"></div>
  </div>
</template>
<script>
import { selectedTheme } from "./services/fetchTheme.js";
export default {
  setup() {
    return { selectedTheme };
  },
};
</script>

<style>
html{ 
  height:100vh;
}
body{ 
  height:100vh;
}
</style>
