import { defineStore } from "pinia";

export const useAppSettingsStore = defineStore("appSettingsStore", {
  state: () => ({
    appearance: {
      theme: "",
    },
    profile: {},
    account: {
      username: "",
      displayname: "",
      countryList: [
        {
          name: "\u00c5land Islands",
          code: "AX",
          required_address_information: [],
        },
        {
          name: "Albania",
          code: "AL",
          required_address_information: [],
        },
        {
          name: "Algeria",
          code: "DZ",
          required_address_information: [],
        },
        {
          name: "American Samoa",
          code: "AS",
          required_address_information: [],
        },
        {
          name: "Andorra",
          code: "AD",
          required_address_information: [],
        },
        {
          name: "Angola",
          code: "AO",
          required_address_information: [],
        },
        {
          name: "Anguilla",
          code: "AI",
          required_address_information: [],
        },
        {
          name: "Antigua and Barbuda",
          code: "AG",
          required_address_information: [],
        },
        {
          name: "Argentina",
          code: "AR",
          required_address_information: [],
        },
        {
          name: "Armenia",
          code: "AM",
          required_address_information: [],
        },
        {
          name: "Aruba",
          code: "AW",
          required_address_information: [],
        },
        {
          name: "Australia",
          code: "AU",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "Austria",
          code: "AT",
          required_address_information: [],
        },
        {
          name: "Azerbaijan",
          code: "AZ",
          required_address_information: [],
        },
        {
          name: "Bahamas",
          code: "BS",
          required_address_information: [],
        },
        {
          name: "Bahrain",
          code: "BH",
          required_address_information: [],
        },
        {
          name: "Bangladesh",
          code: "BD",
          required_address_information: [],
        },
        {
          name: "Barbados",
          code: "BB",
          required_address_information: [],
        },
        {
          name: "Belgium",
          code: "BE",
          required_address_information: [],
        },
        {
          name: "Belize",
          code: "BZ",
          required_address_information: [],
        },
        {
          name: "Benin",
          code: "BJ",
          required_address_information: [],
        },
        {
          name: "Bermuda",
          code: "BM",
          required_address_information: [],
        },
        {
          name: "Bhutan",
          code: "BT",
          required_address_information: [],
        },
        {
          name: "Bolivia",
          code: "BO",
          required_address_information: [],
        },
        {
          name: "Bosnia and Herzegovina",
          code: "BA",
          required_address_information: [],
        },
        {
          name: "Botswana",
          code: "BW",
          required_address_information: [],
        },
        {
          name: "Bouvet Island",
          code: "BV",
          required_address_information: [],
        },
        {
          name: "Brazil",
          code: "BR",
          required_address_information: [],
        },
        {
          name: "British Indian Ocean Territory",
          code: "IO",
          required_address_information: [],
        },
        {
          name: "British Virgin Islands",
          code: "VG",
          required_address_information: [],
        },
        {
          name: "Brunei",
          code: "BN",
          required_address_information: [],
        },
        {
          name: "Bulgaria",
          code: "BG",
          required_address_information: [],
        },
        {
          name: "Burkina Faso",
          code: "BF",
          required_address_information: [],
        },
        {
          name: "Burundi",
          code: "BI",
          required_address_information: [],
        },
        {
          name: "Cambodia",
          code: "KH",
          required_address_information: [],
        },
        {
          name: "Cameroon",
          code: "CM",
          required_address_information: [],
        },
        {
          name: "Canada",
          code: "CA",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "Cape Verde",
          code: "CV",
          required_address_information: [],
        },
        {
          name: "Caribbean Netherlands (Bonaire, Sint Eustatius, and Saba)",
          code: "BQ",
          required_address_information: [],
        },
        {
          name: "Cayman Islands",
          code: "KY",
          required_address_information: [],
        },
        {
          name: "Chad",
          code: "TD",
          required_address_information: [],
        },
        {
          name: "Chile",
          code: "CL",
          required_address_information: [],
        },
        {
          name: "China",
          code: "CN",
          required_address_information: [],
        },
        {
          name: "Christmas Island",
          code: "CX",
          required_address_information: [],
        },
        {
          name: "Cocos Islands",
          code: "CC",
          required_address_information: [],
        },
        {
          name: "Colombia",
          code: "CO",
          required_address_information: [],
        },
        {
          name: "Comoros",
          code: "KM",
          required_address_information: [],
        },
        {
          name: "Cook Islands",
          code: "CK",
          required_address_information: [],
        },
        {
          name: "Costa Rica",
          code: "CR",
          required_address_information: [],
        },
        {
          name: "Croatia",
          code: "HR",
          required_address_information: [],
        },
        {
          name: "Cura\u00e7ao",
          code: "CW",
          required_address_information: [],
        },
        {
          name: "Cyprus",
          code: "CY",
          required_address_information: [],
        },
        {
          name: "Czechia (Czech Republic)",
          code: "CZ",
          required_address_information: [],
        },
        {
          name: "C\u00f4te d'Ivoire (Ivory Coast)",
          code: "CI",
          required_address_information: [],
        },
        {
          name: "Denmark",
          code: "DK",
          required_address_information: [],
        },
        {
          name: "Djibouti",
          code: "DJ",
          required_address_information: [],
        },
        {
          name: "Dominica",
          code: "DM",
          required_address_information: [],
        },
        {
          name: "Dominican Republic",
          code: "DO",
          required_address_information: [],
        },
        {
          name: "Ecuador",
          code: "EC",
          required_address_information: [],
        },
        {
          name: "Egypt",
          code: "EG",
          required_address_information: [],
        },
        {
          name: "El Salvador",
          code: "SV",
          required_address_information: [],
        },
        {
          name: "Equatorial Guinea",
          code: "GQ",
          required_address_information: [],
        },
        {
          name: "Eritrea",
          code: "ER",
          required_address_information: [],
        },
        {
          name: "Estonia",
          code: "EE",
          required_address_information: [],
        },
        {
          name: "Ethiopia",
          code: "ET",
          required_address_information: [],
        },
        {
          name: "Falkland Islands",
          code: "FK",
          required_address_information: [],
        },
        {
          name: "Faroe Islands",
          code: "FO",
          required_address_information: [],
        },
        {
          name: "Fiji",
          code: "FJ",
          required_address_information: [],
        },
        {
          name: "Finland",
          code: "FI",
          required_address_information: [],
        },
        {
          name: "France",
          code: "FR",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "French Guiana",
          code: "GF",
          required_address_information: [],
        },
        {
          name: "French Polynesia",
          code: "PF",
          required_address_information: [],
        },
        {
          name: "French Southern and Antarctic Lands",
          code: "TF",
          required_address_information: [],
        },
        {
          name: "Gabon",
          code: "GA",
          required_address_information: [],
        },
        {
          name: "Gambia",
          code: "GM",
          required_address_information: [],
        },
        {
          name: "Georgia",
          code: "GE",
          required_address_information: [],
        },
        {
          name: "Germany",
          code: "DE",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "Ghana",
          code: "GH",
          required_address_information: [],
        },
        {
          name: "Gibraltar",
          code: "GI",
          required_address_information: [],
        },
        {
          name: "Greece",
          code: "GR",
          required_address_information: [],
        },
        {
          name: "Greenland",
          code: "GL",
          required_address_information: [],
        },
        {
          name: "Grenada",
          code: "GD",
          required_address_information: [],
        },
        {
          name: "Guadeloupe",
          code: "GP",
          required_address_information: [],
        },
        {
          name: "Guam",
          code: "GU",
          required_address_information: [],
        },
        {
          name: "Guatemala",
          code: "GT",
          required_address_information: [],
        },
        {
          name: "Guernsey",
          code: "GG",
          required_address_information: [],
        },
        {
          name: "Guinea",
          code: "GN",
          required_address_information: [],
        },
        {
          name: "Guinea-Bissau",
          code: "GW",
          required_address_information: [],
        },
        {
          name: "Guyana",
          code: "GY",
          required_address_information: [],
        },
        {
          name: "Heard Island and McDonald Islands",
          code: "HM",
          required_address_information: [],
        },
        {
          name: "Holy See (Vatican City)",
          code: "VA",
          required_address_information: [],
        },
        {
          name: "Honduras",
          code: "HN",
          required_address_information: [],
        },
        {
          name: "Hong Kong",
          code: "HK",
          required_address_information: [],
        },
        {
          name: "Hungary",
          code: "HU",
          required_address_information: [],
        },
        {
          name: "Iceland",
          code: "IS",
          required_address_information: [],
        },
        {
          name: "India",
          code: "IN",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "Indonesia",
          code: "ID",
          required_address_information: [],
        },
        {
          name: "Iraq",
          code: "IQ",
          required_address_information: [],
        },
        {
          name: "Ireland",
          code: "IE",
          required_address_information: [],
        },
        {
          name: "Isle of Man",
          code: "IM",
          required_address_information: [],
        },
        {
          name: "Israel",
          code: "IL",
          required_address_information: [],
        },
        {
          name: "Italy",
          code: "IT",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "Jamaica",
          code: "JM",
          required_address_information: [],
        },
        {
          name: "Japan",
          code: "JP",
          required_address_information: [],
        },
        {
          name: "Jersey",
          code: "JE",
          required_address_information: [],
        },
        {
          name: "Jordan",
          code: "JO",
          required_address_information: [],
        },
        {
          name: "Kazakhstan",
          code: "KZ",
          required_address_information: [],
        },
        {
          name: "Kenya",
          code: "KE",
          required_address_information: [],
        },
        {
          name: "Kiribati",
          code: "KI",
          required_address_information: [],
        },
        {
          name: "Kosovo",
          code: "XK",
          required_address_information: [],
        },
        {
          name: "Kuwait",
          code: "KW",
          required_address_information: [],
        },
        {
          name: "Kyrgyzstan",
          code: "KG",
          required_address_information: [],
        },
        {
          name: "Lao People's Democratic Republic (Laos)",
          code: "LA",
          required_address_information: [],
        },
        {
          name: "Latvia",
          code: "LV",
          required_address_information: [],
        },
        {
          name: "Lebanon",
          code: "LB",
          required_address_information: [],
        },
        {
          name: "Lesotho",
          code: "LS",
          required_address_information: [],
        },
        {
          name: "Liberia",
          code: "LR",
          required_address_information: [],
        },
        {
          name: "Liechtenstein",
          code: "LI",
          required_address_information: [],
        },
        {
          name: "Lithuania",
          code: "LT",
          required_address_information: [],
        },
        {
          name: "Luxembourg",
          code: "LU",
          required_address_information: [],
        },
        {
          name: "Macao",
          code: "MO",
          required_address_information: [],
        },
        {
          name: "Macedonia",
          code: "MK",
          required_address_information: [],
        },
        {
          name: "Madagascar",
          code: "MG",
          required_address_information: [],
        },
        {
          name: "Malawi",
          code: "MW",
          required_address_information: [],
        },
        {
          name: "Malaysia",
          code: "MY",
          required_address_information: [],
        },
        {
          name: "Maldives",
          code: "MV",
          required_address_information: [],
        },
        {
          name: "Malta",
          code: "MT",
          required_address_information: [],
        },
        {
          name: "Marshall Islands",
          code: "MH",
          required_address_information: [],
        },
        {
          name: "Martinique",
          code: "MQ",
          required_address_information: [],
        },
        {
          name: "Mauritania",
          code: "MR",
          required_address_information: [],
        },
        {
          name: "Mauritius",
          code: "MU",
          required_address_information: [],
        },
        {
          name: "Mayotte",
          code: "YT",
          required_address_information: [],
        },
        {
          name: "Mexico",
          code: "MX",
          required_address_information: [],
        },
        {
          name: "Micronesia",
          code: "FM",
          required_address_information: [],
        },
        {
          name: "Moldova",
          code: "MD",
          required_address_information: [],
        },
        {
          name: "Monaco",
          code: "MC",
          required_address_information: [],
        },
        {
          name: "Mongolia",
          code: "MN",
          required_address_information: [],
        },
        {
          name: "Montenegro",
          code: "ME",
          required_address_information: [],
        },
        {
          name: "Montserrat",
          code: "MS",
          required_address_information: [],
        },
        {
          name: "Morocco",
          code: "MA",
          required_address_information: [],
        },
        {
          name: "Mozambique",
          code: "MZ",
          required_address_information: [],
        },
        {
          name: "Namibia",
          code: "NA",
          required_address_information: [],
        },
        {
          name: "Nauru",
          code: "NR",
          required_address_information: [],
        },
        {
          name: "Nepal",
          code: "NP",
          required_address_information: [],
        },
        {
          name: "Netherlands",
          code: "NL",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "New Caledonia",
          code: "NC",
          required_address_information: [],
        },
        {
          name: "New Zealand",
          code: "NZ",
          required_address_information: [],
        },
        {
          name: "Niger",
          code: "NE",
          required_address_information: [],
        },
        {
          name: "Nigeria",
          code: "NG",
          required_address_information: [],
        },
        {
          name: "Niue",
          code: "NU",
          required_address_information: [],
        },
        {
          name: "Norfolk Island",
          code: "NF",
          required_address_information: [],
        },
        {
          name: "Northern Mariana Islands",
          code: "MP",
          required_address_information: [],
        },
        {
          name: "Norway",
          code: "NO",
          required_address_information: [],
        },
        {
          name: "Oman",
          code: "OM",
          required_address_information: [],
        },
        {
          name: "Pakistan",
          code: "PK",
          required_address_information: [],
        },
        {
          name: "Palau",
          code: "PW",
          required_address_information: [],
        },
        {
          name: "Palestinian territories",
          code: "PS",
          required_address_information: [],
        },
        {
          name: "Panama",
          code: "PA",
          required_address_information: [],
        },
        {
          name: "Papua New Guinea",
          code: "PG",
          required_address_information: [],
        },
        {
          name: "Paraguay",
          code: "PY",
          required_address_information: [],
        },
        {
          name: "Peru",
          code: "PE",
          required_address_information: [],
        },
        {
          name: "Philippines",
          code: "PH",
          required_address_information: [],
        },
        {
          name: "Pitcairn",
          code: "PN",
          required_address_information: [],
        },
        {
          name: "Poland",
          code: "PL",
          required_address_information: [],
        },
        {
          name: "Portugal",
          code: "PT",
          required_address_information: [],
        },
        {
          name: "Puerto Rico",
          code: "PR",
          required_address_information: [],
        },
        {
          name: "Qatar",
          code: "QA",
          required_address_information: [],
        },
        {
          name: "Republic of Congo",
          code: "CG",
          required_address_information: [],
        },
        {
          name: "Republic of Serbia",
          code: "RS",
          required_address_information: [],
        },
        {
          name: "Reunion",
          code: "RE",
          required_address_information: [],
        },
        {
          name: "Romania",
          code: "RO",
          required_address_information: [],
        },
        {
          name: "Rwanda",
          code: "RW",
          required_address_information: [],
        },
        {
          name: "Saint Barth\u00e9lemy",
          code: "BL",
          required_address_information: [],
        },
        {
          name: "Saint Helena",
          code: "SH",
          required_address_information: [],
        },
        {
          name: "Saint Kitts and Nevis",
          code: "KN",
          required_address_information: [],
        },
        {
          name: "Saint Lucia",
          code: "LC",
          required_address_information: [],
        },
        {
          name: "Saint Martin",
          code: "MF",
          required_address_information: [],
        },
        {
          name: "Saint Pierre and Miquelon",
          code: "PM",
          required_address_information: [],
        },
        {
          name: "Saint Vincent and the Grenadines",
          code: "VC",
          required_address_information: [],
        },
        {
          name: "Samoa",
          code: "WS",
          required_address_information: [],
        },
        {
          name: "San Marino",
          code: "SM",
          required_address_information: [],
        },
        {
          name: "Saudi Arabia",
          code: "SA",
          required_address_information: [],
        },
        {
          name: "Senegal",
          code: "SN",
          required_address_information: [],
        },
        {
          name: "Seychelles",
          code: "SC",
          required_address_information: [],
        },
        {
          name: "Sierra Leone",
          code: "SL",
          required_address_information: [],
        },
        {
          name: "Singapore",
          code: "SG",
          required_address_information: [],
        },
        {
          name: "Sint Maarten",
          code: "SX",
          required_address_information: [],
        },
        {
          name: "Slovakia",
          code: "SK",
          required_address_information: [],
        },
        {
          name: "Slovenia",
          code: "SI",
          required_address_information: [],
        },
        {
          name: "Solomon Islands",
          code: "SB",
          required_address_information: [],
        },
        {
          name: "South Africa",
          code: "ZA",
          required_address_information: [],
        },
        {
          name: "South Georgia and the South Sandwich Islands",
          code: "GS",
          required_address_information: [],
        },
        {
          name: "South Korea",
          code: "KR",
          required_address_information: [],
        },
        {
          name: "Spain",
          code: "ES",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "Sri Lanka",
          code: "LK",
          required_address_information: [],
        },
        {
          name: "Suriname",
          code: "SR",
          required_address_information: [],
        },
        {
          name: "Svalbard and Jan Mayen",
          code: "SJ",
          required_address_information: [],
        },
        {
          name: "Swaziland",
          code: "SZ",
          required_address_information: [],
        },
        {
          name: "Sweden",
          code: "SE",
          required_address_information: [],
        },
        {
          name: "Switzerland",
          code: "CH",
          required_address_information: [],
        },
        {
          name: "S\u00e3o Tom\u00e9 and Pr\u00edncipe",
          code: "ST",
          required_address_information: [],
        },
        {
          name: "Taiwan",
          code: "TW",
          required_address_information: [],
        },
        {
          name: "Tajikistan",
          code: "TJ",
          required_address_information: [],
        },
        {
          name: "Tanzania",
          code: "TZ",
          required_address_information: [],
        },
        {
          name: "Thailand",
          code: "TH",
          required_address_information: [],
        },
        {
          name: "Timor-Leste",
          code: "TL",
          required_address_information: [],
        },
        {
          name: "Togo",
          code: "TG",
          required_address_information: [],
        },
        {
          name: "Tokelau",
          code: "TK",
          required_address_information: [],
        },
        {
          name: "Tonga",
          code: "TO",
          required_address_information: [],
        },
        {
          name: "Trinidad and Tobago",
          code: "TT",
          required_address_information: [],
        },
        {
          name: "Tunisia",
          code: "TN",
          required_address_information: [],
        },
        {
          name: "Turkey",
          code: "TR",
          required_address_information: [],
        },
        {
          name: "Turkmenistan",
          code: "TM",
          required_address_information: [],
        },
        {
          name: "Turks and Caicos Islands",
          code: "TC",
          required_address_information: [],
        },
        {
          name: "Tuvalu",
          code: "TV",
          required_address_information: [],
        },
        {
          name: "U.S. Virgin Islands",
          code: "VI",
          required_address_information: [],
        },
        {
          name: "Uganda",
          code: "UG",
          required_address_information: [],
        },
        {
          name: "Ukraine",
          code: "UA",
          required_address_information: [],
        },
        {
          name: "United Arab Emirates",
          code: "AE",
          required_address_information: ["Region"],
        },
        {
          name: "United Kingdom",
          code: "GB",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "United States",
          code: "US",
          required_address_information: ["ZIP/postal code"],
        },
        {
          name: "United States Minor Outlying Islands",
          code: "UM",
          required_address_information: [],
        },
        {
          name: "Uruguay",
          code: "UY",
          required_address_information: [],
        },
        {
          name: "Uzbekistan",
          code: "UZ",
          required_address_information: [],
        },
        {
          name: "Vanuatu",
          code: "VU",
          required_address_information: [],
        },
        {
          name: "Vietnam",
          code: "VN",
          required_address_information: [],
        },
        {
          name: "Wallis and Futuna",
          code: "WF",
          required_address_information: [],
        },
        {
          name: "Western Sahara",
          code: "EH",
          required_address_information: [],
        },
        {
          name: "Zambia",
          code: "ZM",
          required_address_information: [],
        },
      ],
      address: {
        first_line: "",
        second_line: "",
        city: "",
        region: "",
        postal_code: "",
        country: "",
      },
    },
    themeSelected: "",
    notifications: {},
    privileges: {},
    billing: {
      pricing: {
        annual: {
          personal_basic: "£76.89",
          personal_advanced: "£142.89",
          organisation_basic: "£208.89",
          organisation_advanced: "£299.88",
        },
        monthly: {
          personal_basic: "£6.99",
          personal_advanced: "£12.99",
          organisation_basic: "£18.99",
          organisation_advanced: "£24.99",
        },
      },
      credit: {
        amount: "",
      },
      subscription: {
        tier: "",
        status: "",
        use: "",
        period: "",
        next_payment: "",
        next_billing_date: "",
        cancelled_at: "",
        num_of_total_seats_basic: 0,
        num_of_total_seats_advanced: 0,
        num_of_used_seats_basic: 0,
        num_of_used_seats_advanced: 0,
        next_payment_basic: "",
        next_payment_advanced: "",
      },
      invoices: {
        headers: [
          {
            data_type: "string",
            id: "id",
            name: "ID",
            sortable: false,
          },
          {
            data_type: "string",
            id: "invoice_number",
            name: "Invoice Number",
            sortable: false,
          },
          {
            data_type: "date-time",
            id: "billed_date",
            name: "Billed Date",
            sortable: true,
          },
          {
            data_type: "string",
            id: "total_amount",
            name: "Total Amount",
            sortable: false,
          },
          {
            data_type: "string",
            id: "status",
            name: "Status",
            sortable: false,
          },
        ],
        rows: [
          {
            billed_date: "2024-08-28T19:39:44.33899Z",
            currency_code: "GBP",
            id: "txn_01j6d80jajp696x50sjj4xf8e7",
            invoice_number: "7525-10037",
            status: "completed",
            total_amount: "32485",
          },
        ],
        total: 1,
      },
      usage: {
        g_tokens: 0,
        storage_in_mb: 0,
      },
      gtok: 0,
      paddle: {
        transaction_id: null,
      },
    },
    user: {
      status: "",
      tier: "",
    },
  }),
  getters: {
    getUsername: (state) => {
      return state.account.username;
    },
    getDisplayname: (state) => {
      return state.account.displayname;
    },
    getCountryList: (state) => {
      return state.account.countryList;
    },
    getAddrFirstLine: (state) => {
      return state.account.address.first_line;
    },
    getAddrSecondLine: (state) => {
      return state.account.address.second_line;
    },
    getAddrCity: (state) => {
      return state.account.address.city;
    },
    getAddrRegion: (state) => {
      return state.account.address.region;
    },
    getAddrPostalcode: (state) => {
      return state.account.address.postal_code;
    },
    getAddrCountry: (state) => {
      return state.account.address.country;
    },
    getMonthlyPersonalBasic: (state) => {
      return state.billing.pricing.monthly.personal_basic;
    },
    getAnnualPersonalBasic: (state) => {
      return state.billing.pricing.annual.personal_basic;
    },
    getMonthlyPersonalAdvanced: (state) => {
      return state.billing.pricing.monthly.personal_advanced;
    },
    getAnnualPersonalAdvanced: (state) => {
      return state.billing.pricing.annual.personal_advanced;
    },
    getMonthlyOrganisationBasic: (state) => {
      return state.billing.pricing.monthly.organisation_basic;
    },
    getAnnualOrganisationBasic: (state) => {
      return state.billing.pricing.annual.organisation_basic;
    },
    getMonthlyOrganisationAdvanced: (state) => {
      return state.billing.pricing.monthly.organisation_advanced;
    },
    getAnnualOrganisationAdvanced: (state) => {
      return state.billing.pricing.annual.organisation_advanced;
    },
    getSubscriptionStatus: (state) => {
      return state.billing.subscription.status;
    },
    getSavedTheme: (state) => {
      return state.appearance.theme;
    },
    getThemeSelected: (state) => {
      return state.themeSelected;
    },
    getCreditAmount: (state) => {
      return state.billing.credit.amount;
    },
    getSubscriptionCancelledAt: (state) => {
      return state.billing.subscription.cancelled_at;
    },
    getSubscriptionTier: (state) => {
      return state.billing.subscription.tier;
    },
    getSubscriptionUse: (state) => {
      return state.billing.subscription.use;
    },
    getSubscriptionPeriod: (state) => {
      return state.billing.subscription.period;
    },
    getNextPayment: (state) => {
      return state.billing.subscription.next_payment;
    },
    getNextPaymentBasic: (state) => {
      return state.billing.subscription.next_payment_basic;
    },
    getNextPaymentAdvanced: (state) => {
      return state.billing.subscription.next_payment_advanced;
    },
    getNextBillingDate: (state) => {
      return state.billing.subscription.next_billing_date;
    },
    getNumOfTotalSeatsBasic: (state) => {
      return state.billing.subscription.num_of_total_seats_basic;
    },
    getNumOfTotalSeatsAdvanced: (state) => {
      return state.billing.subscription.num_of_total_seats_advanced;
    },
    getNumOfUsedSeatsBasic: (state) => {
      return state.billing.subscription.num_of_used_seats_basic;
    },
    getNumOfUsedSeatsAdvanced: (state) => {
      return state.billing.subscription.num_of_used_seats_advanced;
    },
    getGTokens: (state) => {
      return state.billing.gtok;
    },
    getGTokensUsage: (state) => {
      return state.billing.usage.g_tokens;
    },
    getStorageMB: (state) => {
      return state.billing.usage.storage_in_mb;
    },
    getTotalRecords: (state) => {
      return state.billing.invoices.total;
    },
    getPageLimit: (state) => {
      return state.billing.invoices.pageLimit;
    },
    getPages: (state) => {
      return state.billing.invoices.pages;
    },
    getLoading: (state) => {
      return state.billing.invoices.isLoading;
    },
    getId: (state) => {
      return (id) => state.billing.invoices.headers[parseInt(id)]["id"];
    },
    getRowId: (state) => {
      return (id) => state.billing.invoices.rows[parseInt(id)]["id"];
    },
    getHeaderIndex: (state) => {
      return (id) =>
        state.billing.invoices.headers.findIndex((x) => x.id === id);
    },
  },
  actions: {
    setUsername(payload) {
      this.account.username = payload.email;
    },
    setDisplayname(payload) {
      this.account.displayname = payload.dispname;
    },
    setAddrFirstLine(payload) {
      this.account.address.first_line = payload.first_line;
    },
    setAddrSecondLine(payload) {
      this.account.address.second_line = payload.second_line;
    },
    setAddrCity(payload) {
      this.account.address.city = payload.city;
    },
    setAddrRegion(payload) {
      this.account.address.region = payload.region;
    },
    setAddrPostalcode(payload) {
      this.account.address.postal_code = payload.postal_code;
    },
    setAddrCountry(payload) {
      this.account.address.country = payload.country;
    },
    setAnnualPersonalBasic(payload) {
      this.billing.pricing.annual.personal_basic = payload.annPersonalBasic;
    },
    setAnnualPersonalAdvanced(payload) {
      this.billing.pricing.annual.personal_advanced =
        payload.annPersonalAdvanced;
    },
    setMonthlyPersonalBasic(payload) {
      this.billing.pricing.monthly.personal_basic = payload.monPersonalBasic;
    },
    setMonthlyPersonalAdvanced(payload) {
      this.billing.pricing.monthly.personal_advanced =
        payload.monPersonalAdvanced;
    },
    setAnnualOrganisationBasic(payload) {
      this.billing.pricing.annual.organisation_basic =
        payload.annOrganisationBasic;
    },
    setAnnualOrganisationAdvanced(payload) {
      this.billing.pricing.annual.organisation_advanced =
        payload.annOrganisationAdvanced;
    },
    setMonthlyOrganisationBasic(payload) {
      this.billing.pricing.monthly.organisation_basic =
        payload.monOrganisationBasic;
    },
    setMonthlyOrganisationAdvanced(payload) {
      this.billing.pricing.monthly.organisation_advanced =
        payload.monOrganisationAdvanced;
    },
    setSubscriptionStatus(payload) {
      this.billing.subscription.status = payload.status;
    },
    setSavedTheme(payload) {
      this.appearance.theme = payload.theme;
    },
    setTheme(payload) {
      this.themeSelected = payload.theme;
    },
    setCreditAmount(payload) {
      this.billing.credit.amount = payload.amount;
    },
    setSubscriptionCancelledAt(payload) {
      this.billing.subscription.cancelled_at = payload.canceled_at;
    },
    setSubscriptionTier(payload) {
      this.billing.subscription.tier = payload.tier;
    },
    setSubscriptionUse(payload) {
      this.billing.subscription.use = payload.use;
    },
    setPeriod(payload) {
      this.billing.subscription.period = payload.period;
    },
    setNextPayment(payload) {
      this.billing.subscription.next_payment = payload.paym;
    },
    setNextPaymentBasic(payload) {
      this.billing.subscription.next_payment_basic = payload.paym_basic;
    },
    setNextPaymentAdvanced(payload) {
      this.billing.subscription.next_payment_advanced = payload.paym_advanced;
    },
    setNextBillingDate(payload) {
      this.billing.subscription.next_billing_date = payload.date;
    },
    setNumOfTotalSeatsBasic(payload) {
      this.billing.subscription.num_of_total_seats_basic = payload.val_basic;
    },
    setNumOfTotalSeatsAdvanced(payload) {
      this.billing.subscription.num_of_total_seats_advanced =
        payload.val_advanced;
    },
    setNumOfUsedSeatsBasic(payload) {
      this.billing.subscription.num_of_used_seats_basic = payload.val_basic;
    },
    setNumOfUsedSeatsAdvanced(payload) {
      this.billing.subscription.num_of_used_seats_advanced =
        payload.val_advanced;
    },
    setGTokens(payload) {
      this.billing.gtok = payload.val;
    },
    setGTokensUsage(payload) {
      this.billing.usage.g_tokens = payload.val;
    },
    setStorageMB(payload) {
      this.billing.usage.storage_in_mb = payload.val;
    },
    setTransactionID(payload) {
      this.billing.paddle.transaction_id = payload.id;
    },
    setPageLimit(limit) {
      this.billing.invoices["pageLimit"] = limit;
    },
    setPages(num) {
      this.billing.invoices["pages"] = num;
    },
    setInvoices(payload) {
      this.billing.invoices = payload;
    },
    resetSubscription(payload) {
      this.billing.subscription.tier = "";
      this.billing.subscription.status = "";
      this.billing.subscription.use = "";
      this.billing.subscription.period = "";
      this.billing.subscription.next_payment = "";
      this.billing.subscription.next_billing_date = "";
      this.billing.subscription.cancelled_at = "";
      this.billing.subscription.num_of_total_seats_basic = 0;
      this.billing.subscription.num_of_total_seats_advanced = 0;
      this.billing.subscription.num_of_used_seats_basic = 0;
      this.billing.subscription.num_of_used_seats_advanced = 0;
      this.billing.subscription.next_payment_basic = "";
      this.billing.subscription.next_payment_advanced = "";
    },
    resetPricing(payload) {
      this.billing.pricing.annual.personal_basic = "";
      this.billing.pricing.annual.personal_advanced = "";
      this.billing.pricing.annual.organisation_basic = "";
      this.billing.pricing.annual.organisation_advanced = "";
      this.billing.pricing.monthly.personal_basic = "";
      this.billing.pricing.monthly.personal_advanced = "";
      this.billing.pricing.monthly.organisation_basic = "";
      this.billing.pricing.monthly.organisation_advanced = "";
    },
    resetAddress(payload) {
      this.account.address.first_line = "";
      this.account.address.second_line = "";
      this.account.address.city = "";
      this.account.address.region = "";
      this.account.address.postal_code = "";
      this.account.address.country = "";
    },
  },
  persist: true,
});
